import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

const LoginToComment = ({ isOpen, dismiss, goToLogin }) => {
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  const handleDismiss = () => {
    setVisible(false);
    if (dismiss) dismiss();
  };

  if (!visible) return null;

  return (
    <PopupContainer>
      <Popup>
        <PopupContent>
          <CloseButton onClick={handleDismiss}>&times;</CloseButton>
          <ContentWrapper>
            <Title>Leave a Comment</Title>
            <Description>You can either log in to comment as yourself or leave a comment anonymously.</Description>
            <ButtonGroup>
              <CommentButton onClick={handleDismiss}>Comment Anonymously</CommentButton>
              <LoginButton onClick={goToLogin}>Log In</LoginButton>
            </ButtonGroup>
          </ContentWrapper>
        </PopupContent>
      </Popup>
    </PopupContainer>
  );
};

// Styled components

const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`;

const Popup = styled.div`
  background-color: #2c2c2e;
  border-radius: 12px;
  padding: 24px;
  width: 400px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  position: relative;
  text-align: center;
`;

const PopupContent = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font-size: 1.8em;
  color: #f0f0f0;
`;

const Description = styled.p`
  margin-bottom: 24px;
  font-size: 1em;
  color: #a0a0a0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

const CommentButton = styled.button`
  padding: 10px 20px;
  background-color: var(--primary-color-dark);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary-color-dark);
    transform: scale(1.05); /* Adds a slight scale effect */
  }
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  background-color: #202124;
  color: #fff;
  border: 2px solid var(--primary-color-dark);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary-color-dark);
    color: #ffffff;
  }
`;

export default LoginToComment;
