import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { useAuth } from 'oidc-react';
import { useNavigate, useLocation } from 'react-router-dom';

import StaticLinkTab from '../../presentations/components/popup/StaticLinkTab';
import CreatePublicLinkTab from '../../presentations/components/popup/CreatePublicLinkTab';
import SendMailTab from '../../presentations/components/popup/SendMailTab';

const CONTENT_TABS = ['public', 'addons'];

const ShareModal = ({
  dismissModal,
  presentationId,
  title,
  url,
  openTrackingEnabled,
  emailNotificationsEnabled,
  shareType,
}) => {
  const [contentTab, setContentTab] = useState(CONTENT_TABS[0]);
  const { userDetails: user } = useAuth();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const onClose = async () => {
    if (hash === '#share') {
      navigate('#');
    }
    dismissModal();
  };

  useEffect(() => {
    setContentTab(CONTENT_TABS[0]);
  }, []);

  const renderTabContent = () => {
    switch (contentTab) {
      case 'public':
        return (
          <CreatePublicLinkTab presentationId={presentationId} user={user} closeModal={onClose} />
        );
      case 'links':
        return (
          <StaticLinkTab
            presentationId={presentationId}
            presentationTitle={title}
            url={url}
            user={user}
            openTrackingEnabled={openTrackingEnabled}
            emailNotificationsEnabled={emailNotificationsEnabled}
            setPreviousTitle={() => {}}
            closePopup={onClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalContainer>
      <DarkOverlay onClick={onClose} />
      <Popup>
        <PopupContent>
          <PopupHeader>
            <PopupTitle>Share document</PopupTitle>
            <CloseButton onClick={onClose} />
          </PopupHeader>
          <TabContent>{renderTabContent()}</TabContent>
        </PopupContent>
      </Popup>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const DarkOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
`;

const Popup = styled.div`
  background-color: var(--background-color);
  border-radius: 40px;
  box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.55);
  width: 29%;
  @media (max-width: 768px) {
    width: 100%;
  }
  max-height: 130vh;
  z-index: 1000;
  position: fixed;
  top: 15%;
`;

const PopupContent = styled.div`
  padding: 35px;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const PopupTitle = styled.label`
  font-size: 1.6em;
  font-weight: 600;
`;

const CloseButton = styled(AiOutlineClose)`
  cursor: pointer;
  font-size: 1.5em;
  color: #555;
`;

const TabContent = styled.div`
  background-color: var(--background-color);
  padding: 0px;
  border: 0px solid #e0e0e0;
  border-top: none;
`;

export default ShareModal;
